.productsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.itemsList {
  margin-left: 3rem;
}

.loginContainer {
    max-width: 500px;
    margin: 11rem auto;
}

div.formContainer {
    margin: 1.5rem
}

.formContainer .formInput {
    margin-bottom: 1rem;
}

.formContainer .formButton {
    margin: 1rem auto;
}

.formContainer .formError {
    margin: 1rem auto;
}
